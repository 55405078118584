<template>
  <div>
    <el-card style="margin-top:20px">
      <el-button @click="addDistrict"
                 type="primary">+ 新增</el-button>
      <el-table :data="districtList"
                border
                stripe>
        <el-table-column label="id"
                         width="100"
                         prop="id"></el-table-column>
        <el-table-column label="楼层"
                         prop="name"> </el-table-column>
        <el-table-column label="平面图"
                         prop="plan">
          <template slot-scope="scope">
            <el-button type="primary"
                       @click="getPlan(scope.row)"
                       size="small">查看平面图</el-button>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <div>
              <el-button size="mini"
                         type="primary"
                         @click="editDistrict(scope.row)">修改</el-button>
              <el-button size="mini"
                         type="danger"
                         @click="deleteDistrict(scope.row.id)">删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination :total="total"
                     @size-change="handleSizeChange"
                     :current-page="queryInfo.pageNo"
                     :page-size="queryInfo.limit"
                     :page-sizes="[15, 30, 50, 100]"
                     @current-change="handleCurrentChange"
                     layout="total,sizes,prev,pager,next,jumper"></el-pagination>
    </el-card>
    <!-- 添加楼层对话框 -->
    <el-dialog @close="clearAddInfo"
               :title="title"
               :visible.sync="addGoodsCateDialogVisible"
               width="40%">
      <el-form ref="districtFormRef"
               :model="districtListForm"
               :rules="districtFormRules"
               label-width="100">
        <el-form-item label="楼层"
                      prop="name">
          <!-- <el-input v-model="districtListForm.name"
                    placeholder="请输入楼层"></el-input> -->
          <el-select v-model="districtListForm.name"
                     placeholder="请选择">
            <el-option v-for="item in options"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="平面图"
                      prop="plan"
                      v-model="districtListForm.plan.url">
          <el-upload action="api/v1/file/file"
                     :data="this.bizType1"
                     :on-remove="handleRemove"
                     :limit="3"
                     ref="upload"
                     :before-upload="beforeUpload"
                     :on-success="handlesuccess"
                     :file-list="fileList"
                     list-type="picture">
            <el-button size="small"
                       type="primary">点击上传</el-button>
            <div slot="tip"
                 class="el-upload__tip">
              只能上传3张,且是jpg/png文件
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="addGoodsCateDialogVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="submit">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 查看平面 -->
    <el-dialog :title="title"
               :visible.sync="addVisible"
               width="40%">
      <div v-for="(item, index) in planList"
           :key="index">
        <img style="height:100%;width:50%"
             :src="item" />
      </div>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="addVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      queryInfo: {
        limit: 15,
        pageNo: 1,
        marketId: ''
      },
      districtList: [],
      total: 0,
      marketId: '',
      // 新增和修改公用一个对话框
      title: '',
      // 新增对话框，默认false
      addGoodsCateDialogVisible: false,
      addVisible: false,
      districtListForm: {
        name: '',
        marketId: '',
        plan: []
      },
      districtId: '',
      submitType: '',
      districtFormRules: {
        name: [{ required: true, message: '请输入楼层', trigger: 'blur' }],
        plan: [{ required: true, message: '请上传平面图', trigger: 'blur' }]
      },
      // 上传业态图标的type
      bizType1: {
        bizType: 'districtPlan'
      },
      // 上传的平面列表
      fileList: [],
      // 平面图列表
      planList: [],
      value: '',
      options: [
        { value: 'F1', label: 'F1' },
        { value: 'F2', label: 'F2' },
        { value: 'F3', label: 'F3' },
        { value: 'F4', label: 'F4' },
        { value: 'F5', label: 'F5' },
        { value: 'F6', label: 'F6' },
        { value: 'F7', label: 'F7' },
        { value: 'F8', label: 'F8' },
        { value: 'F9', label: 'F9' },
        { value: 'F10', label: 'F10' }
      ],
      echoList: []

      // id: '' // 删除的id
    }
  },
  created() {
    this.getDistrictList()
  },
  mounted() {},
  methods: {
    async getDistrictList() {
      this.queryInfo.marketId = window.sessionStorage.getItem('currentMarketId')
      const res = await this.$http.get('agriculturalMarketArea/list', {
        params: this.queryInfo
      })
      if (res.data.code !== 0) {
        return this.$message.error(res.message)
      }
      this.districtList = res.data.data.data
      this.total = res.data.data.total
      // console.log(res, 111)
    },
    handleSizeChange(newSize) {
      this.queryInfo.limit = newSize
      this.getDistrictList()
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pageNo = newPage
      this.getDistrictList()
    },
    handleRemove(file, fileList) {
      // console.log(file, fileList, 111)
      this.districtListForm.plan = fileList.map((item) => {
        return item.response.data
      })
      // console.log(this.districtListForm.plan, '移除')
    },
    beforeUpload(file) {
      const types = ['image/jpeg', 'image/jpg', 'image/png', 'image/bmp']
      const isImage = types.includes(file.type)
      if (!isImage) {
        this.$message.error('上传图片只能是jpg、jpeg、bmp、png格式')
        return false
      }
      return true
    },
    handlesuccess(response, file, fileList) {
      // console.log(response, '11拿到')
      // console.log(fileList, '拿到')
      this.districtListForm.plan = fileList.map((item) => {
        return item.response.data
      })
      // console.log(this.districtListForm.plan, '11wj')
    },
    addDistrict() {
      this.districtListForm.marketId = window.sessionStorage.getItem(
        'currentMarketId'
      )
      // console.log(row, '添')
      this.addGoodsCateDialogVisible = true
      this.title = '添加楼层'
      this.submitType = 'add'
    },
    editDistrict(row) {
      this.districtListForm = row
      this.districtId = row.id
      // this.plan = row.plan
      // this.fileList = row.plan
      // console.log(row, '修')
      // console.log(this.fileList, '回显')
      this.addGoodsCateDialogVisible = true
      this.title = '修改楼层'
      this.submitType = 'edit'
    },
    submit() {
      this.$refs.districtFormRef.validate(async (valid) => {
        if (!valid) return
        // 可以发起网络请求
        if (this.submitType === 'add') {
          // this.districtListForm.plan = this.fileList
          const { data: res } = await this.$http.post(
            'agriculturalMarketArea',
            this.districtListForm
          )
          if (res.code !== 0) {
            return this.$message.error(res.message)
          }
          this.$message.success('添加楼层成功')
          this.getDistrictList()
          this.addGoodsCateDialogVisible = false
        } else {
          const { data: res } = await this.$http.put(
            `agriculturalMarketArea/${this.districtId}`,
            this.districtListForm
          )
          if (res.code !== 0) {
            return this.$message.error(res.message)
          }
          this.$message.success('修改楼层成功')
          this.getDistrictList()
          this.addGoodsCateDialogVisible = false
        }
      })
    },
    // 关闭对话框
    clearAddInfo() {
      this.districtListForm = {}
      this.districtListForm.plan = []
      this.$refs.upload.clearFiles()
      // this.fileList = ''
      // this.planList = ''
      this.$refs.districtFormRef.resetFields()
      this.getDistrictList()
    },
    // 删除楼层
    async deleteDistrict(id) {
      // console.log(id)
      // 弹框提醒
      const confirmResult = await this.$confirm(
        '此操作将永久删除该楼层，是否继续？',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch((err) => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除')
      }
      // 提交
      const { data: res } = await this.$http.delete(
        `/agriculturalMarketArea/${id}`
      )
      if (res.code !== 0) {
        return this.$message.error('删除楼层失败')
      }
      this.$message.success('删除楼层成功')
      this.getDistrictList()
    },
    getPlan(row) {
      // console.log(row, 22111)
      this.title = '查看平面图'
      this.addVisible = true
      this.planList = row.plan.map((item) => {
        return item.url
      })
    }
  }
}
</script>

<style lang="less" scoped>
.imgsUpdata {
  width: 150px;
  height: 150px;
  border: 1px solid #000;
}
</style>
